<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
        <div v-if="!isLoading">
          <b-row class="mx-0">
            <div class="data-info">
              <base-input
                vid="title"
                name="title"
                label="Titolo"
                v-model="form.title"
                :placeholder="`Inserisci un titolo identificativo dei risultati`"
                :rules="{ required: true }"
              />
              <base-textarea
                name="description"
                label="Descrizione"
                v-model="form.description"
                :placeholder="`Inserisci una breve descrizione che ti aiuterà a ricordare maggiori dettagli sui risultati`"
              ></base-textarea>
            </div>
          </b-row>
          <b-row>
            <b-col align="right">
              <b-button
                type="button"
                variant="lisaweb"
                class="mb-2"
                :disabled="invalid"
                @click="handleSubmit(onSubmit)"
                >Salva</b-button
              >
            </b-col>
          </b-row>
        </div>
      </validation-observer>
      <template #overlay>
        <div class="text-center mt-6">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseTextarea from "@/components/form/BaseTextarea";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  name: "Edit",
  data() {
    return {
      isLoading: true,
      repository: "analysis",
      resource: "analysis",
      fetchedTitle: null,
      fetchedDescription: null,
      form: {
        title: null,
        description: null,
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseTextarea,
  },
  props: {
    resourceId: Number,
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      const update_data_payload = {
        name: this.form.title,
        description: this.form.description,
      };
      const Repo = RepositoryFactory.get(this.rep);
      Repo.update_info_data(this.resourceId, update_data_payload)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: risultato aggiornato`,
          });
          this.$bvModal.hide("modal");
          this.$emit("fetch");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onSearch() {
      this.isLoading = true;
      const Repo = RepositoryFactory.get(this.rep);
      Repo.preview(this.resourceId)
        .then((response) => {
          const data = response.data.data;
          console.log("data", response.data.data);
          this.form.title = data?.name ?? null;
          this.form.description = data?.description ?? null;
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
  mounted() {
    this.onSearch();
  },
};
</script>

<style scoped>
.data-info {
  width: 100%;
}
</style>
