import { render, staticRenderFns } from "./crossingMethod.vue?vue&type=template&id=6d7fb132&scoped=true&"
import script from "./crossingMethod.vue?vue&type=script&lang=js&"
export * from "./crossingMethod.vue?vue&type=script&lang=js&"
import style0 from "./crossingMethod.vue?vue&type=style&index=0&id=6d7fb132&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d7fb132",
  null
  
)

export default component.exports