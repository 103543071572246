<template>
  <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
    <b-modal
      :id="modalId"
      title="Salva Dati"
      size="lg"
      ok-only
      header-bg-variant="lisaweb"
      scrollable
    >
      <p class="modal-desc">
        Per poter salvare i dati compila i seguenti campi:
      </p>

      <div class="data-info">
        <base-input
          vid="title"
          name="title"
          label="Titolo"
          v-model="form.title"
          :placeholder="`Inserisci un titolo identificativo dei dati`"
          :rules="{ required: true }"
        />
        <base-textarea
          name="description"
          label="Descrizione"
          v-model="form.description"
          :placeholder="`Inserisci una breve descrizione che ti aiuterà a ricordare maggiori dettagli sui dati estratti`"
        ></base-textarea>
      </div>

      <template #modal-footer>
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="handleSubmit(onSubmit)"
          :disabled="invalid"
        >
          Salva dati
        </b-btn>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
// import SaveMethod from "@/components/analysis/components/saveMethod.vue";
import BaseInput from "@/components/form/BaseInput";
import BaseTextarea from "@/components/form/BaseTextarea";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  mixins: [],
  data() {
    return {
      repository: "analysis",
      resource: "analysis",
      modalId: "modalSalvaDati",
      isLoading: false,
      form: {
        title: "",
        description: "",
      },
    };
  },
  props: {
    payload: {
      type: Object,
      default() {
        return {};
      },
    },
    saveType: {
      type: String,
      default() {
        return "";
      },
    },
    crossType: {
      type: String,
      default() {
        return "";
      },
    },
    activeResults: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    rep() {
      return this.repository;
    },
    // hasMethod() {
    //   return Object.keys(this.loadedMethod).length !== 0 &&
    //     this.loadedMethod.constructor === Object
    //     ? true
    //     : false;
    // },
  },
  methods: {
    hideModal(id) {
      console.log("hideModal");
      this.$bvModal.hide(id);
    },
    onSubmit() {
      this.isLoading = true;
      console.log("Salva Dati");
      const Repo = RepositoryFactory.get(this.rep);
      let save_data_payload;
      if (this.saveType === "cross") {
        save_data_payload = {};
        save_data_payload.data = this.activeResults;
        save_data_payload.type = this.crossType;
      } else {
        save_data_payload = structuredClone(this.payload);
      }
      save_data_payload.name = this.form.title;
      save_data_payload.description = this.form.description;
      (this.saveType === "cross"
        ? Repo.data_cross_save(save_data_payload)
        : Repo.save_data(save_data_payload)
      )
        .then((response) => {
          const data = response;
          console.log("method", data);
          this.hideModal(this.modalId);
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: dati salvati`,
          });
          // if (this.selected === 1) {
          //   this.selected = 0;
          //   this.form.title = this.form.new_method.title;
          //   this.form.description = this.form.new_method.description;
          //   this.form.new_method.title = "";
          //   this.form.new_method.description = "";
          // }
          this.$emit("savedData");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
      // setTimeout(() => {
      //   this.$emit("loadedMethodSelected", this.selected);
      // }, 1000);
    },
  },
  components: { BaseInput, BaseTextarea },
};
</script>
<style scoped>
.modal-desc {
  font-size: 13px;
  padding: 0 5px 0 0;
  line-height: 1.5em;
  margin: 0 0 5px;
}
/* .loaded-method {
  padding: 10px;
  margin: 0 0 5px;
  background: #fff;
  .value {
    color: #000;
  }
} */
</style>
