<template>
  <div class="saved-method">
    <div class="info">
      <b-form-radio
        :value="method.id"
        name="saved_method"
        v-model="radioValue"
        >{{ method.name }}</b-form-radio
      >
      <div class="extra-info">
        <p class="desc" v-if="method.description">{{ method.description }}</p>
        <p class="last-update">
          Ultimo aggiornamento: {{ formatDate(method.updated_at) }}
        </p>
      </div>
    </div>
    <div class="actions">
      <b-button
        variant="lisaweb"
        class=""
        size="sm"
        title="Rimuovi metodo"
        :disabled="loadedMethod === method.id"
        @click="removeMethod(method.id)"
      >
        <b-icon icon="trash"></b-icon>
      </b-button>
    </div>
  </div>
</template>
<script>
import { toLocaleDate } from "@/utils/dates";
export default {
  mixins: [],
  data() {
    return {
      radioValue: false,
    };
  },
  props: {
    // value: {
    //   type: String,
    //   default() {
    //     return "";
    //   },
    // },
    loadedMethod: Number,
    method: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {},
  methods: {
    formatDate(date) {
      return toLocaleDate(date, "DD/MM/YYYY - HH:mm");
    },
    removeMethod(method_id) {
      this.$emit("destroyMethod", method_id);
    },
    // hideModal(){
    // $emit('');
    // }
    // enableModalBtn() {
    //   console.log("radio clicked");
    // },
  },
  components: {},
};
</script>
<style scoped>
.saved-method {
  display: grid;
  grid-template-columns: 1fr 40px;
  /* grid-template-rows: repeat(2, 1fr); */
  grid-column-gap: 0px;
  /* grid-row-gap: 0px; */
  padding: 10px 15px 20px;
  border-bottom: 1px solid #707070;
  margin: 0;
  width: calc(100% - 15px);
  transition: background 0.2s linear;
  &:has(input[type="radio"]:checked) {
    background: #fff;
  }
  &:last-of-type {
    border: 0;
  }
  .extra-info {
    padding-left: 1.5rem;
  }
  .desc,
  .last-update {
    margin: 0;
  }
  .desc {
    line-height: 1.5em;
    margin: 0 0 5px;
  }
  .last-update {
    color: #999;
  }
  .info {
    grid-area: 1 / 1 / 2 / 2;
  }
  .actions {
    grid-area: 1 / 2 / 2 / 3;
    align-self: center;
    justify-self: end;
  }
}
</style>
<style>
#modalCaricaMetodo .custom-control-label {
  font-size: 14px;
  color: #000;
}
</style>
