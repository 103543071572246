<template>
  <b-modal
    id="modalCaricaMetodo"
    title="Carica Metodo"
    size="lg"
    ok-only
    header-bg-variant="lisaweb"
    @hidden="hideModal()"
  >
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <template v-if="savedMethods.length">
          <p class="modal-desc">
            Seleziona uno dei metodi che hai creato in precedenza per popolare
            l'interfaccia con i relativi campi, condizioni e risultato.<br />Una
            volta completato il caricamento automatico, potrai ancora apportare
            delle modifiche, prima di procedere all'esecuzione.
          </p>
          <p class="loaded-method" v-if="loadedMethodTitle">
            <strong :data-method-id="loadedMethod"
              >Metodo attualmente caricato:
              <span class="value">{{ loadedMethodTitle }}</span>
            </strong>
          </p>
          <div class="search-wrapper">
            <b-input-group size="sm" class="search-bar">
              <b-input-group-prepend is-text class="search-bar-icon">
                <b-icon icon="search"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                autocomplete="off"
                type="search"
                placeholder="Digita un metodo da ricercare"
                size="sm"
                class="mr-sm-2"
                v-model="searchedTerms"
                @keyup="filterData"
              >
              </b-form-input>
            </b-input-group>
            <p
              style="padding: 2px 5px; background: #fff; text-align: right"
              v-if="activeSavedMethods.length"
            >
              <template v-if="isFiltering()"
                >stai visualizzando
                <strong>{{ activeSavedMethods.length }}</strong>
                {{ activeSavedMethods.length > 1 ? "metodi" : "metodo" }}
                su <strong>{{ savedMethods.length }}</strong></template
              >
              <template v-else>
                <strong>{{ savedMethods.length }}</strong>
                {{ savedMethods.length > 1 ? "metodi" : "metodo" }}
              </template>
            </p>
          </div>
          <div v-if="activeSavedMethods.length">
            <b-form-radio-group
              id="radiosCaricaMetodo"
              v-model="selected"
              name="radio-options-carica-metodo"
            >
              <saved-methods
                v-for="(method, index) in activeSavedMethods"
                :key="index"
                :method="method"
                @destroyMethod="destroyMethod"
                :loadedMethod="loadedMethod"
              ></saved-methods>
            </b-form-radio-group>
          </div>
          <div v-else>
            <p class="no-res">
              Non ci sono metodi che corrispondono alla ricerca.
            </p>
          </div>
        </template>
        <template v-else>
          <p class="modal-desc">Non ci sono metodi salvati.</p>
        </template>
      </div>
      <template #overlay>
        <div class="text-center mt-6">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
    <template #modal-footer>
      <template v-if="savedMethods.length">
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          :disabled="selected !== null ? false : true"
          @click="loadMethod"
        >
          Carica metodo
        </b-btn>
      </template>
      <template v-else>
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="$bvModal.hide('modalCaricaMetodo')"
        >
          OK
        </b-btn>
      </template>
    </template>
  </b-modal>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import SavedMethods from "@/components/analysis/components/savedMethods.vue";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import { toLocaleDate } from "@/utils/dates";

export default {
  mixins: [ConfirmModalMixin],
  data() {
    return {
      repository: "analysis",
      resource: "analysis",
      selected: null,
      isLoading: true,
      // filtering: false,
      // innerLoadedMethod: {},
      loadedMethodTitle: null,
      // loadedMethodId: null,
      filteredSavedMethods: [],
      activeSavedMethods: [],
      savedMethods: [],
      searchedTerms: null,
    };
  },
  props: {
    loadedMethod: Number,
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
  methods: {
    formatDate(date) {
      return toLocaleDate(date, "DD/MM/YYYY - HH:mm");
    },
    htmlToVNode(html) {
      // https://github.com/bootstrap-vue/bootstrap-vue/issues/4073
      // https://github.com/bootstrap-vue/bootstrap-vue/issues/3491
      return [this.$createElement("div", { domProps: { innerHTML: html } })];
    },
    // hiddenModal() {
    //   this.selected = this.innerLoadedMethod.id;
    // },
    isFiltering() {
      return this.savedMethods.length !== this.activeSavedMethods.length
        ? true
        : false;
    },
    hideModal(id) {
      console.log("hideModal");
      this.$bvModal.hide(id);
      this.filteredSavedMethods = [];
      this.activeSavedMethods = [];
      this.searchedTerms = null;
    },
    filterData(event) {
      const inputValue = event.target.value;
      console.log(inputValue);
      // this.filteredFields = this.fields;
      this.filteredSavedMethods = JSON.parse(JSON.stringify(this.savedMethods));

      this.activeSavedMethods = this.filteredSavedMethods.filter(
        (method) =>
          method?.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
          method?.description?.toLowerCase().includes(inputValue.toLowerCase())
      );

      console.log("activeSavedMethods", this.activeSavedMethods);
    },
    loadMethod() {
      // this.innerLoadedMethod = this.savedMethods.find(
      //   (x) => x.id === this.selected
      // );
      // this.$emit("loadedMethodSelected", this.selected);
      // this.loadedMethodId = this.innerLoadedMethod.id;
      const loadedMethodObj = this.getLoadedMethod(this.selected);
      // this.$emit("loadedMethodSelected", this.selected, this.loadedMethodTitle);
      this.$emit("loadedMethodSelected", loadedMethodObj, true);
    },
    destroyMethod(method_id) {
      console.log("method_id", method_id);
      const method = this.savedMethods.find((m) => m.id === method_id);
      let msg;
      msg = `
        <p>Vuoi eliminare il seguente metodo?</p>
        <p>
          <strong class="title">${method.name}</strong><br />
          ${
            method.description
              ? `<span class="desc">${method.description}</span><br />`
              : ""
          }
          <span class="last-update">Ultimo aggiornamento: ${this.formatDate(
            method.updated_at
          )}</span>
        </p>
      `;
      msg = this.htmlToVNode(msg);
      this.showConfirm({
        yesCallback: () => {
          this.isLoading = true;
          const Repo = RepositoryFactory.get(this.rep);
          Repo.destroy_method(method_id)
            .then((response) => {
              console.log("response", response);
              this.getAllMethods();
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: errMsg,
              });
            });
        },
        noCallback: null,
        title: "Conferma operazione",
        message: msg,
        yesLabel: "Elimina metodo",
        noLabel: "Annulla",
      });
    },
    getAllMethods() {
      this.isLoading = true;
      const Repo = RepositoryFactory.get(this.rep);
      const queryString = "perPage=0";
      Repo.get_methods(queryString)
        .then((response) => {
          console.log("method", response.data.data);
          this.savedMethods = response.data.data;
          this.activeSavedMethods = response.data.data;
          if (
            this.savedMethods &&
            this.savedMethods.length &&
            this.loadedMethod
          ) {
            this.selected = this.loadedMethod;
            // this.getLoadedMethodTitle(this.loadedMethod);
            this.loadedMethodTitle = this.savedMethods.find(
              (m) => m.id === this.loadedMethod
            ).name;
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // selectedRadioCaricaMetodo(event) {
    //   console.log("change", event);
    //   console.log("loadedMethodId: ", this.loadedMethodId);
    // },
    getLoadedMethod(current_method_id) {
      return this.savedMethods.find((m) => m.id === current_method_id);
    },
  },
  components: { BaseIcon, SavedMethods },
  // beforeMount() {
  //   this.getAllMethods();
  // },
  mounted() {
    // usare questo se devo eseguire prima di mostrare
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      console.debug(
        "Modal addCorrespondenceModals about to be shown",
        bvEvent,
        modalId
      );
      if (modalId !== "modalCaricaMetodo") return;
      this.getAllMethods();
    });
  },
  beforeDestroy() {
    this.$root.$off("bv::modal::show");
  },
};
</script>
<style scoped>
.modal-desc {
  font-size: 13px;
  padding: 0 5px 0 0;
  line-height: 1.5em;
}
#radiosCaricaMetodo {
  max-height: 45vh;
  overflow-y: auto;
  padding: 0 0 60px;
}
</style>
<style>
#modalCaricaMetodo input[type="radio"],
#modalCaricaMetodo label[for] {
  cursor: pointer;
}
#modalCaricaMetodo input[type="radio"]:checked,
#modalCaricaMetodo input[type="radio"]:checked + label {
  cursor: default;
}
#modalCaricaMetodo .modal-footer {
  position: relative;
  &:before {
    position: absolute;
    display: block;
    width: calc(100% - 30px);
    height: 65px;
    left: 0;
    top: calc(-100% + -20px);
    content: "";
    pointer-events: none;
    touch-action: none;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    z-index: 1;
    background: linear-gradient(0deg, #f3f0ea, hsla(0, 0%, 100%, 0));
  }
}
.loaded-method {
  padding: 10px;
  margin: 0 0 5px;
  background: #fff;
  .value {
    color: #000;
  }
}
.no-res {
  padding: 0;
  text-align: center;
  margin: 10px 0 5rem;
}
/* Search */
.search-wrapper {
  position: sticky;
  top: 0;
  padding: 0;
  z-index: 1;
}
.input-group-text .b-icon.bi {
  color: white;
  width: 40px;
}
.input-group-text {
  background-color: #9c9c9c;
  border: none;
}
.search-bar,
.mr-sm-2.form-control.form-control-sm {
  background-color: #9c9c9c;
  color: white;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white !important;
  font-size: 14px;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: white !important;
  font-size: 14px;
  opacity: 1; /* Firefox */
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
/* Search */
</style>
