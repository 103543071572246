<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <analysis-paginated-data-table
        :fields="fields"
        :repository="repository"
        :resource="resource"
        :filterName="filterName"
        :items="items"
        :ref="tableRef"
        noActions
      ></analysis-paginated-data-table>
      <template #overlay>
        <div class="text-center mt-6">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import AnalysisPaginatedDataTable from "@/components/tables/AnalysisPaginatedDataTable.vue";
import { mapActions } from "vuex";
import { getDictionary } from "@/utils/dictionary";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  name: "Paginated",
  data() {
    return {
      isLoading: true,
      repository: "analysis",
      resource: "analysis",
      tableRef: "ResultPaginatedDataAnalysisTableRef",
      filterName: "filterResultPaginatedDataAnalysis",
      filter: this.initFilter(),
      fields: [],
      items: [],
      current_config: {},
    };
  },
  components: {
    BaseIcon,
    AnalysisPaginatedDataTable,
  },
  props: {
    resourceId: Number,
  },
  methods: {
    getDictionary,
    initFilter() {
      let init = {
        byAttribute: {
          title: null,
        },
      };
      return init;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
  mounted() {
    this.isLoading = true;
    const queryString = "perPage=1";
    const Repo = RepositoryFactory.get(this.rep);
    Repo.paginated(this.resourceId, queryString)
      .then((response) => {
        console.log(response.data.data);
        this.fields = Object.keys(response.data.data[0]).map((key) => {
          const obj = {};
          obj["key"] = key;
          obj["label"] = key.split(".")[1];
          return obj;
        });
        this.current_config.verb = {
          resourceId: this.resourceId,
          name: "paginated",
          canVerb: true,
        };
        this.isLoading = false;
        this.$refs[this.tableRef].fetch(this.current_config);
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({ preset: "error", text: errMsg });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style scoped lang="scss">
.closed-insurance-policies {
  margin-top: 30px;
}
:deep(.b-overlay > div:first-of-type) {
  opacity: 1 !important;
}
:deep(.b-overlay > div:last-of-type) {
  background: #f3f0ea;
  margin: 0 !important;
  width: 100%;
  > * {
    margin: 0 !important;
    position: relative;
    top: 18px;
  }
}
</style>
