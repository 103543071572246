<template>
  <div :class="`draggable-field${draggableClasses}`" :code="code">
    <span class="text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  mixins: [],
  data() {
    return {};
  },
  props: {
    hasRules: {
      type: Number,
      default() {
        return 0;
      },
    },
    hasResults: {
      type: Number,
      default() {
        return 0;
      },
    },
    code: {
      type: String,
      default() {
        return "";
      },
    },
    text: {
      type: String,
      default() {
        return "";
      },
    },
    activeRules: {
      type: Array,
      default() {
        return [];
      },
    },
    activeResults: {
      type: Array,
      default() {
        return [];
      },
    },
    activeFields: {
      type: Array,
      default() {
        return [];
      },
    },
    field: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    /*
    isRule() {
      // console.log("isRule check - activeRules", this.activeRules);
      // console.log("isRule check - field", this.field);
      // if (!this.activeRules.length) return false;
      // let index = this.activeRules.indexOf(this.field);
      // console.log("isRule: ", index >= 0 ? true : false);
      // return index >= 0 ? true : false;
      let res = false;
      this.activeRules.forEach((o) => {
        if (o.code === this.field.code) res = true;
      });
      return res;
    },
    isResult() {
      // console.log("isResult check", this.activeResults);
      // if (!this.activeResults.length) return false;
      // let index = this.activeResults.indexOf(this.field);
      // console.log("isResult: ", index > 0 ? true : false);
      // return index >= 0 ? true : false;
      let res = false;
      this.activeResults.forEach((o) => {
        if (o.code === this.field.code) res = true;
      });
      return res;
    },
    */
    checkInArray() {
      return (array) => {
        let res = false;
        array.forEach((o) => {
          if (o.code === this.field.code) res = true;
        });
        return res;
      };
    },
    draggableClasses() {
      let c = "";
      // if (this.isRule) c += " draggable-field--has-rule";
      // if (this.isResult) c += " draggable-field--has-result";
      if (this.checkInArray(this.activeRules))
        c += " draggable-field--has-rule";
      if (this.checkInArray(this.activeResults))
        c += " draggable-field--has-result";
      // console.log("draggableClasses: ", c);
      return c;
    },
  },
  methods: {},
  components: {},
};
</script>
<style scoped>
.draggable-field {
  font-size: 14px;
  background: #fff;
  height: 50px;
  /* line-height: 50px; */
  border: 1px solid #000;
  position: relative;
  /* transition: background 0.2s linear; */
  /* text-align: center; */
  .text {
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.draggable-field--has-rule {
  background: #f9991e;
}
.draggable-field--has-result {
  background: #107c41;
}
.draggable-field--has-rule.draggable-field--has-result {
  /* background: linear-gradient(
    to right,
    #f9991e,
    #f9991e 50%,
    #107c41 50%,
    #107c41
  ); */
  background: linear-gradient(
    225deg,
    #f9991e,
    #f9991e 50%,
    #107c41 50%,
    #107c41
  );
}
</style>
