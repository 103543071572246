<template>
  <b-modal
    id="addResultDataCrossingModal"
    size="lg"
    scrollable
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="file-ruled"></b-icon>
        <span>Seleziona un risultato</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>
    <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn @click="cancel()" size="sm" variant="lisaweb">Chiudi</b-btn>
      </b-form-group>
    </template>
    <analysis-results-table
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      noActions
      :ref="tableRef"
      @autofetch="autofetch"
      includeRowSelector
      @rowSelector="onModalSelector"
      :parentActiveMethods="parentActiveMethods"
    ></analysis-results-table>
  </b-modal>
</template>
<script>
import AnalysisResultsTable from "@/components/tables/AnalysisResultsTable.vue";
import { toLocaleDate } from "@/utils/dates";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      isLoading: false,
      repository: "analysis",
      resource: "analyses",
      tableRef: "ResultDataAnalysisTableRef",
      filterName: "filterResultDataAnalysis",
      filter: this.initFilter(),
      fields: [
        {
          key: "rowSelector",
          label: "Azioni",
          thStyle: {
            width: "5px",
          },
        },
        {
          key: "name",
          value: "name",
          label: this.getDictionary("name", "analysis"),
          // sortable: true,
          // sortKey: "byAttribute.title",
        },
        {
          key: "description",
          value: "description",
          label: this.getDictionary("description", "analysis"),
        },
        {
          key: "created_at",
          value: "created_at",
          label: this.getDictionary("created_at", "analysis"),
          formatter: (value) => toLocaleDate(value, "DD/MM/YYYY HH:mm:ss"),
        },
      ],
      parentActiveMethods: [],
    };
  },
  components: {
    AnalysisResultsTable,
  },
  methods: {
    autofetch() {
      this.parentActiveMethods = this.activeMethods;
      this.$refs[this.tableRef].fetch();
    },
    onModalSelector(method) {
      this.$emit("input", method);
    },
    initFilter() {
      let init = {
        byAttribute: {
          title: null,
        },
      };
      return init;
    },
  },
  props: {
    activeMethods: {
      type: Array,
      default() {
        return [];
      },
    },
    //   subject: {
    //     type: String,
    //   },
    //   email: {
    //     type: String,
    //   },
  },
};
</script>

<style scoped>
.subject {
  font-weight: bold;
  font-size: 1.5em;
}
</style>
