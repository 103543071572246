<template>
  <div class="mt-2">
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono record da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
        </b-table>
      </div>
      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import BaseInputPagination from "@/components/form/BaseInputPagination";
import BaseIcon from "@/components/BaseIcon";
import template from "./template";

export default {
  name: "ResultPaginatedDataAnalysisTableRef",
  extends: template,
  data() {
    return {};
  },
  components: {
    BaseIcon,
    BaseInputPagination,
  },
  methods: {},
};
</script>

<style lang="css" scoped></style>
