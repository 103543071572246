<template>
  <div class="mx-1 result-wrapper" v-if="canVerb(resource, 'index')">
    <!-- <div class="toolbar">
      <div class="btn-group-wrapper">
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="openModal('modalSalvaDati')"
          :title="`${alreadySavedData ? 'Dati già salvati' : 'Salva Dati'}`"
          :disabled="alreadySavedData"
          >Salva Dati</b-btn
        >
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="onOpenSaveMethodModal()"
          >Salva Metodo</b-btn
        >
      </div>
      <div class="btn-group-wrapper">
        <b-btn variant="lisaweb" class="" size="sm" :disabled="true"
          >Prepara spedizioni</b-btn
        >
      </div>
      <div class="btn-group-wrapper">
        <b-btn variant="lisaweb" class="" size="sm" @click="exportData()"
          >Scarica CSV</b-btn
        >
      </div>
    </div> -->
    <analysis-results-table
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :onlyActions="['edit', 'destroy', 'infomodal']"
      @download="downloadCsv"
      @destroy="onDestroy"
      :ref="tableRef"
    ></analysis-results-table>
    <!-- <modal-save-data
      @savedData="savedData"
      :payload="current_config"
    ></modal-save-data>
    <modal-save-method
      @loadedMethodSelected="setLoadedMethod"
      :payload="current_config"
      :loadedMethodObj="loaded_method"
    ></modal-save-method> -->
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>
<script>
import AnalysisResultsTable from "@/components/tables/AnalysisResultsTable.vue";
// import ModalSaveData from "@/components/analysis/components/modalSaveData.vue";
// import ModalSaveMethod from "@/components/analysis/components/modalSaveMethod.vue";
// import CustomCSVMixin from "@/mixins/CustomCSVMixin";
// import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import { mapActions } from "vuex";
import { toLocaleDate } from "@/utils/dates";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      alreadySavedData: false,
      isLoading: false,
      exportUrl: null,
      repository: "analysis",
      resource: "analyses",
      tableRef: "ResultDataAnalysisTableRef",
      filterName: "filterResultDataAnalysis",
      filter: this.initFilter(),
      // current_config: this.$route.params.current_config,
      // loaded_method: this.$route.params.loaded_method,
      // data_table: this.$route.params.data_table,
      fields: [
        {
          key: "name",
          value: "name",
          label: this.getDictionary("name", "analysis"),
          // sortable: true,
          // sortKey: "byAttribute.title",
        },
        {
          key: "description",
          value: "description",
          label: this.getDictionary("description", "analysis"),
        },
        {
          key: "created_at",
          value: "created_at",
          label: this.getDictionary("created_at", "analysis"),
          formatter: (value) => toLocaleDate(value, "DD/MM/YYYY HH:mm:ss"),
        },
        {
          key: "download",
          label: this.getDictionary("download", "analysis"),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
      ],
      // items: [],
    };
  },
  components: {
    AnalysisResultsTable,
    // ModalSaveMethod,
    // ModalSaveData,
  },
  beforeMount() {
    console.log("current_config", this.current_config);
    console.log("data_table", this.data_table);
    // this.isLoading = true;
    // const Repo = RepositoryFactory.get(this.rep);
    // Repo.execute(this.current_config)
    //   .then((response) => {
    //     console.log("response", response);
    //     this.items = response.data.data;
    //   })
    //   .catch((error) => {
    //     let errMsg = this.$getErrorMessage(error);
    //     this.$showSnackbar({
    //       preset: "error",
    //       text: errMsg,
    //     });
    //   })
    //   .finally(() => {
    //     this.isLoading = false;
    //   });
  },
  mounted() {
    // console.log("current_config", this.current_config);
    // console.log("data_table", this.data_table);
    // this.$refs[this.tableRef].fetch();
    // this.exportUrl = this.$refs[this.tableRef].loadFilter(
    //   this.$refs[this.tableRef].filterName
    // );
    // this.current_config.verb = {
    //   name: "execute",
    //   canVerb: true,
    // };
    this.$refs[this.tableRef].fetch();
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
  methods: {
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy_data(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Risultato eliminato con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    downloadCsv(item) {
      this.isLoading = true;
      const Repo = RepositoryFactory.get(this.rep);
      Repo.download_from_data(item.id)
        .then((response) => {
          const data = response;
          console.log("method", data);
          const blob = new Blob([response.data], { type: "text/csv;" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Analisi_${moment().format("YYYYMMDDHHmmss")}`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // savedData() {
    //   this.alreadySavedData = true;
    // },
    // openModal(id) {
    //   console.log("openModal");
    //   this.$bvModal.show(id);
    // },
    // onOpenSaveMethodModal() {
    //   // this.generatePayload();
    //   debugger;
    //   this.openModal("modalSalvaMetodo");
    // },
    initFilter() {
      let init = {
        byAttribute: {
          title: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    formatEntity(value) {
      const splitted_string = value.split("\\");
      if (splitted_string.length === 3) {
        switch (splitted_string[2]) {
          case "Registry":
            return "Anagrafica";
          case "Claim":
            return "Sinistro";
          case "InsurancePolicy":
            return "Polizza";
        }
      }
      return "";
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
};
</script>
<style scoped>
.toolbar {
  position: sticky;
  top: 48;
  right: 0;
  height: 52px;
  grid-column: 1 / span 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 3;
}
.btn-group-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  ~ .btn-group-wrapper {
    padding: 0 0 0 20px;
    border-left: 1px solid #ccc;
    margin: 0 0 0 20px;
  }
}
</style>
