<template>
  <div class="mx-1 result-wrapper">
    <div class="toolbar">
      <div class="btn-group-wrapper">
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="openModal('modalSalvaDati')"
          :title="`${alreadySavedData ? 'Dati già salvati' : 'Salva Dati'}`"
          :disabled="alreadySavedData"
          >Salva Dati</b-btn
        >
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="onOpenSaveMethodModal()"
          >Salva Metodo</b-btn
        >
      </div>
      <div class="btn-group-wrapper">
        <b-btn variant="lisaweb" class="" size="sm" :disabled="true"
          >Prepara spedizioni</b-btn
        >
      </div>
      <div class="btn-group-wrapper">
        <b-btn variant="lisaweb" class="" size="sm" @click="exportData()"
          >Scarica CSV</b-btn
        >
      </div>
    </div>
    <analysis-data-table
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :items="items"
      :ref="tableRef"
      noActions
    ></analysis-data-table>
    <div class="py-2 mb-4">
      <b-button
        @click="goBack()"
        variant="lisaweb"
        size="sm"
        class="float-right"
        ><b-icon-chevron-double-left
          font-scale="0.9"
        ></b-icon-chevron-double-left
        >Torna indietro</b-button
      >
    </div>
    <modal-save-data
      @savedData="savedData"
      :payload="current_config"
      saveType="execute"
    ></modal-save-data>
    <modal-save-method
      @loadedMethodSelected="setLoadedMethod"
      :payload="current_config"
      :loadedMethodObj="loaded_method"
    ></modal-save-method>
  </div>
</template>
<script>
import AnalysisDataTable from "@/components/tables/AnalysisDataTable.vue";
import ModalSaveData from "@/components/analysis/components/modalSaveData.vue";
import ModalSaveMethod from "@/components/analysis/components/modalSaveMethod.vue";
import CustomCSVMixin from "@/mixins/CustomCSVMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import { mapActions } from "vuex";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [CustomFiltersMixin, CustomCSVMixin],
  data() {
    return {
      alreadySavedData: false,
      isLoading: false,
      exportUrl: null,
      repository: "analysis",
      resource: "analyses",
      tableRef: "ResultDataAnalysisTableRef",
      filterName: "filterResultDataAnalysis",
      filter: this.initFilter(),
      current_config: this.$route.params.current_config,
      loaded_method: this.$route.params.loaded_method,
      returnUrl: this.$route.params.returnUrl
        ? this.$route.params.returnUrl
        : null,
      // data_table: this.$route.params.data_table,
      fields: this.$route.params.data_table,
      items: [],
    };
  },
  components: {
    AnalysisDataTable,
    ModalSaveMethod,
    ModalSaveData,
  },
  beforeMount() {
    console.log("current_config", this.current_config);
    console.log("data_table", this.data_table);
    // this.isLoading = true;
    // const Repo = RepositoryFactory.get(this.rep);
    // Repo.execute(this.current_config)
    //   .then((response) => {
    //     console.log("response", response);
    //     this.items = response.data.data;
    //   })
    //   .catch((error) => {
    //     let errMsg = this.$getErrorMessage(error);
    //     this.$showSnackbar({
    //       preset: "error",
    //       text: errMsg,
    //     });
    //   })
    //   .finally(() => {
    //     this.isLoading = false;
    //   });
  },
  mounted() {
    // console.log("current_config", this.current_config);
    // console.log("data_table", this.data_table);
    // this.$refs[this.tableRef].fetch();
    // this.exportUrl = this.$refs[this.tableRef].loadFilter(
    //   this.$refs[this.tableRef].filterName
    // );
    this.current_config.verb = {
      name: "execute",
      canVerb: true,
    };
    this.$refs[this.tableRef].fetch(this.current_config);
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
  methods: {
    goBack() {
      if (this.returnUrl) {
        switch (this.returnUrl) {
          case "analysis":
            this.$router.push({
              name: "analysis.index",
              params: {
                onLandData: {
                  loaded_method: this.loaded_method,
                  current_config: this.current_config,
                },
              },
            });
            break;
          default:
            console.log(
              "No returnUrl set matched! Fallback to default back behavior."
            );
            this.$router.back();
            break;
        }
      } else {
        this.$router.back();
      }
    },
    savedData() {
      this.alreadySavedData = true;
    },
    exportData() {
      this.isLoading = true;
      const Repo = RepositoryFactory.get(this.rep);
      Repo.download(this.current_config)
        .then((response) => {
          const data = response;
          console.log("method", data);
          const blob = new Blob([response.data], { type: "text/csv;" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Analisi_${moment().format("YYYYMMDDHHmmss")}`;
          link.click();
          URL.revokeObjectURL(link.href);
          // this.$showSnackbar({
          //   preset: "success",
          //   text: `Azione Completata: file scaricato salvati`,
          // });
          // if (this.selected === 1) {
          //   this.selected = 0;
          //   this.form.title = this.form.new_method.title;
          //   this.form.description = this.form.new_method.description;
          //   this.form.new_method.title = "";
          //   this.form.new_method.description = "";
          // }
          // this.$emit("loadedMethodSelected", response.data.data, false);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
      // setTimeout(() => {
      //   this.$emit("loadedMethodSelected", this.selected);
      // }, 1000);
    },
    setLoadedMethod(method_obj, reload_view) {
      console.log("method_obj", method_obj);
      console.log("reload_view", reload_view);
      this.loaded_method = method_obj;
    },
    openModal(id) {
      console.log("openModal");
      this.$bvModal.show(id);
    },
    onOpenSaveMethodModal() {
      // this.generatePayload();
      this.openModal("modalSalvaMetodo");
    },
    initFilter() {
      let init = {
        byAttribute: {
          title: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    formatEntity(value) {
      const splitted_string = value.split("\\");
      if (splitted_string.length === 3) {
        switch (splitted_string[2]) {
          case "Registry":
            return "Anagrafica";
          case "Claim":
            return "Sinistro";
          case "InsurancePolicy":
            return "Polizza";
        }
      }
      return "";
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
};
</script>
<style scoped>
.toolbar {
  position: sticky;
  top: 48;
  right: 0;
  height: 52px;
  grid-column: 1 / span 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 3;
}
.btn-group-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  ~ .btn-group-wrapper {
    padding: 0 0 0 20px;
    border-left: 1px solid #ccc;
    margin: 0 0 0 20px;
  }
}
</style>
