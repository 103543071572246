<template>
  <div class="mx-1 data-result-index">
    <!-- <div class="data-result-header">
    </div> -->
    <b-card class="card--analysis-result" :header="letter">
      <div class="inner-result-card-wrapper">
        <div>
          <b-card-text>
            <!-- <strong>{{ result[letter].name }}</strong> -->
            <strong>{{ result.name }}</strong>
          </b-card-text>
          <template v-if="result.description">
            <b-card-text>
              <span>{{ result.description }}</span>
            </b-card-text>
          </template>
          <b-card-text>
            <span
              >Ultimo aggiornamento: {{ formatDate(result.updated_at) }}</span
            >
          </b-card-text>
        </div>
        <b-button
          variant="lisaweb"
          class=""
          size="sm"
          title="Rimuovi"
          @click="removeDataReult(result)"
        >
          <b-icon icon="trash"></b-icon>
        </b-button>
      </div>
    </b-card>
    <!-- <pre>{{ result }}</pre>
    <pre>{{ letter }}</pre> -->
    <p class="mt-3"><strong>Colonne</strong></p>
    <div class="columns-wrapper">
      <div
        class="column-item"
        v-for="(column, index) in result.columns"
        :key="index"
        :data-column="column"
      >
        {{ column.split(".")[1] }}
      </div>
    </div>
  </div>
</template>
<script>
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import { toLocaleDate } from "@/utils/dates";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      exportUrl: null,
      repository: "analysis",
      resource: "analyses",
    };
  },
  props: {
    result: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default() {
        return 0;
      },
    },
    letter: {
      type: String,
      default() {
        return "";
      },
    },
  },
  components: {},
  beforeMount() {},
  mounted() {},
  computed: {},
  methods: {
    formatDate(date) {
      return toLocaleDate(date, "DD/MM/YYYY - HH:mm");
    },
    removeDataReult(result) {
      this.$emit("removeResult", result);
    },
  },
};
</script>
<style scoped lang="scss">
.data-result-index {
  width: 50%;
  overflow: hidden;
}
.data-result-header {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 30px;
}
.letter {
  margin: 0;
}
:deep(.card-text) {
  margin: 0;
}
.columns-wrapper {
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  padding: 0;
}
.column-item {
  font-size: 14px;
  background: #fff;
  height: 50px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.inner-result-card-wrapper {
  display: grid;
  grid-template-columns: 1fr 40px;
}
</style>
