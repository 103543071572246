<template>
  <div v-if="hasFields">
    <div class="group-fields" v-for="(field, index) in fields" :key="index">
      <h4 class="title" v-if="field.fields.length">{{ field.text }}</h4>
      <div class="fields-inner-wrapper" v-if="field.fields.length">
        <drag
          class="drag"
          :data="element"
          v-for="(element, index) in field.fields"
          :key="index"
          @dragstart="handleDragStart"
        >
          <fields-draggable
            :code="element.code"
            :text="element.text"
            :hasRules="hasRules"
            :hasResults="hasResults"
            :activeRules="activeRules"
            :activeResults="activeResults"
            :field="element"
          >
          </fields-draggable>
        </drag>
      </div>
    </div>
  </div>
  <div v-else>
    <p class="no-res">Non ci sono campi che corrispondono alla ricerca.</p>
  </div>
</template>

<script>
import { Drag } from "vue-easy-dnd";
import FieldsDraggable from "@/components/analysis/components/fieldsDraggable.vue";
export default {
  mixins: [],
  data() {
    return {};
  },
  props: {
    hasRules: {
      type: Number,
      default() {
        return 0;
      },
    },
    hasResults: {
      type: Number,
      default() {
        return 0;
      },
    },
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
    activeRules: {
      type: Array,
      default() {
        return [];
      },
    },
    activeResults: {
      type: Array,
      default() {
        return [];
      },
    },
    activeFields: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    hasFields() {
      return this.fields.filter((f) => f.fields.length !== 0).length
        ? true
        : false;
    },
  },
  methods: {
    handleDragStart(event) {
      console.log("drag has start", event);
    },
  },
  components: {
    Drag,
    FieldsDraggable,
  },
};
</script>
<style scoped>
.fields-inner-wrapper {
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 10px;
}
.group-fields {
  margin: 1px 0 0;
  padding: 0 10px;
  .title {
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    margin: 0 0 12px;
    background: rgba(255, 255, 255, 0.7);
  }
  ~ .group-fields {
    margin: 45px 0 0;
  }
  /* &:last-of-type {
    margin-bottom: 66px;
  } */
}
.no-res {
  padding: 0;
  text-align: center;
}
</style>
