<template>
  <div
    :class="`flex-item ${status ? 'active' : ''}`"
    @click="selectSource(code)"
  >
    <p class="title">{{ title }}</p>
    <div class="hint-wrapper">
      <div
        v-for="(hint, index) in hints"
        :key="index"
        :class="`hint hint--${hint.code}${
          hint.code === 'rules' ? activeClassRules : activeClassResults
        }`"
        :title="hint.text"
      >
        <span class="sr-only">{{ hint.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mixins: [],
  data() {
    return {
      hints: [
        {
          code: "rules",
          text: `Sono presenti campi di tipo ${this.title} nelle Condizioni.`,
        },
        {
          code: "results",
          text: `Sono presenti campi di tipo ${this.title} nel Risultato.`,
        },
      ],
    };
  },
  props: {
    title: {
      type: String,
      default() {
        return "";
      },
    },
    code: {
      type: String,
      default() {
        return "";
      },
    },
    status: {
      type: Boolean,
      default() {
        return false;
      },
    },
    fields: {
      type: Object,
      default() {
        return {};
      },
    },
    activeRules: {
      type: Array,
      default() {
        return [];
      },
    },
    activeResults: {
      type: Array,
      default() {
        return [];
      },
    },
    activeFields: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    checkInArray() {
      return (array) => {
        return array.some((el) => this.code === el.ref);
      };
    },
    activeClassRules() {
      let c = "";
      if (this.checkInArray(this.activeRules)) c += " hint--active";
      return c;
    },
    activeClassResults() {
      let c = "";
      if (this.checkInArray(this.activeResults)) c += " hint--active";
      return c;
    },
  },
  methods: {
    selectSource(code) {
      this.$emit("clickedSource", code);
    },
  },
};
</script>
<style scoped>
.flex-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fafafa;
  position: relative;
  align-items: center;
  width: 120px;
  /* height: 55px; */
  height: 47px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #aea7a2;
  transition: 0.2s background linear;
  .title {
    margin: 0;
  }
  &:before {
    display: block;
    width: calc(100% + 42px);
    height: calc(100% + 14px);
    position: absolute;
    top: -7px;
    left: -20px;
    content: "";
    background-color: #d5d5d5;
    opacity: 0;
    z-index: -1;
    transition: 0.2s opacity linear;
    pointer-events: none;
    touch-action: none;
    cursor: default;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  &.active {
    background: #fff;
    cursor: default;
    text-decoration: underline;
    &:before {
      opacity: 1;
    }
  }
}
@media (hover: hover) and (pointer: fine) {
  .flex-item {
    &:hover {
      text-decoration: underline;
    }
  }
}
.hint-wrapper {
  display: flex;
  gap: 4px;
  margin: 6px 0 0;
  &:not(:has(.hint--rules.hint--active)) {
    &:not(:has(.hint--results.hint--active)) {
      display: none;
    }
  }
}
.hint {
  display: none;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.hint--active {
  display: block;
}
.hint--rules {
  background: #f9991e;
}
.hint--results {
  background: #107c41;
}
</style>
