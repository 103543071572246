<template>
  <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
    <b-modal
      :id="modalId"
      title="Salva Metodo"
      size="lg"
      ok-only
      header-bg-variant="lisaweb"
      scrollable
      @hidden="hideModal()"
    >
      <b-overlay center :show="isLoading" rounded="sm">
        <div v-if="!isLoading">
          <div class="modal-desc-wrapper" v-if="hasMethod">
            <p class="modal-desc">
              Questo metodo è stato configurato a partire da un metodo
              precedentemente salvato:
            </p>
            <p class="loaded-method">
              <strong :data-method-id="loadedMethodObj.id"
                >Metodo attualmente caricato:
                <span class="value">{{ loadedMethodObj.name }}</span></strong
              >
            </p>
            <p class="modal-desc">
              Per favore, seleziona una delle seguenti opzioni per poter
              continuare:
            </p>
          </div>
          <p class="modal-desc" v-else>
            Per poter salvare il nuovo metodo compila i seguenti campi:
          </p>
          <b-form-radio-group
            id="radiosSalvaMetodo"
            v-model="selected"
            :options="options"
            name="save_method"
          >
          </b-form-radio-group>
          <!-- <div v-if="hasMethod">
        <b-form-radio value="0" name="save_method" v-model="selected"
          >Sovrascrivi metodo caricato: {{ loadedMethod.title }}
        </b-form-radio>
        <save-method
          v-for="(method, index) in [loadedMethod, newMethod]"
          :key="index"
          :method="method"
          :hasMethod="hasMethod"
        ></save-method>
        <div class="method-info" v-if="selectedRadio == '0'">
          <base-input
            vid="title"
            name="override_title"
            label="Titolo"
            v-model="form.title"
            :placeholder="`Inserisci un titolo identificativo del metodo`"
            :rules="{ required: true }"
          />
          <base-textarea
            name="override_description"
            label="Descrizione"
            v-model="form.description"
            :placeholder="`Inserisci una breve descrizione che ti aiuterà a ricordare maggiori dettagli sul metodo`"
          ></base-textarea>
          <p class="last-update" v-if="isRealMethod">
            Ultimo aggiornamento: {{ formatDate(method.last_update) }}
          </p>
        </div>
      </div>
      <b-form-radio value="1" name="save_method" v-model="selected"
        >Salva come nuovo metodo</b-form-radio
      >
    </b-form-radio-group> -->
          <div class="method-info" v-if="selected == '0'">
            <base-input
              vid="title"
              name="title"
              label="Titolo"
              v-model="form.title"
              :placeholder="`Inserisci un titolo identificativo del metodo`"
              :rules="{ required: true }"
            />
            <base-textarea
              name="description"
              label="Descrizione"
              v-model="form.description"
              :placeholder="`Inserisci una breve descrizione che ti aiuterà a ricordare maggiori dettagli sul metodo`"
            ></base-textarea>
          </div>
          <div class="method-info" v-else-if="selected == '1'">
            <base-input
              vid="title"
              name="title"
              label="Titolo"
              v-model="form.new_method.title"
              :placeholder="`Inserisci un titolo identificativo del metodo`"
              :rules="{ required: true }"
            />
            <base-textarea
              name="description"
              label="Descrizione"
              v-model="form.new_method.description"
              :placeholder="`Inserisci una breve descrizione che ti aiuterà a ricordare maggiori dettagli sul metodo`"
            ></base-textarea>
          </div>
          <!-- <save-method
      v-if="hasMethod"
      :method="loadedMethod"
      :hasMethod="hasMethod"
    ></save-method>

    <save-method :method="newMethod" :hasMethod="hasMethod"></save-method> -->

          <!-- <save-method
      v-for="(element, index) in [loadedMethod, {}]"
      :key="index"
      :index="index"
      :hasMethod="hasMethod"
      :element="element"
    ></save-method> -->
        </div>
        <template #overlay>
          <div class="text-center mt-6">
            <base-icon name="loading" width="35" height="35" />
            <p id="cancel-label">Operazione in corso...</p>
          </div>
        </template>
      </b-overlay>
      <template #modal-footer="">
        <b-form-group>
          <!-- <b-btn @click="cancel()" size="sm" variant="outline-secondary"
            >Annulla</b-btn
          > -->
          <b-btn
            variant="lisaweb"
            class=""
            size="sm"
            :disabled="invalid"
            @click="handleSubmit(onSubmit)"
          >
            Salva metodo
          </b-btn>
        </b-form-group>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseTextarea from "@/components/form/BaseTextarea";
// import SaveMethod from "@/components/analysis/components/saveMethod.vue";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  mixins: [],
  data() {
    return {
      modalId: "modalSalvaMetodo",
      isLoading: true,
      loadedMethodOption: false,
      form: {
        id: null,
        title: "",
        description: "",
        new_method: {
          title: "",
          description: "",
        },
      },
      options: [
        {
          text: "Salva come nuovo metodo",
          value: 1,
        },
      ],
      // selectedRadio: this.hasMethod ? null : "1",
      repository: "analysis",
      resource: "analysis",
      selected: null,
    };
  },
  props: {
    loadedMethodObj: {
      type: Object,
      default() {
        return {};
      },
    },
    payload: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    rep() {
      return this.repository;
    },
    hasMethod() {
      // return Object.keys(this.loadedMethod).length !== 0 &&
      //   this.loadedMethod.constructor === Object
      //   ? true
      //   : false;
      // return this.loadedMethod ? true : false;
      return Object.prototype.hasOwnProperty.call(this.loadedMethodObj, "id")
        ? true
        : false;
    },
  },
  methods: {
    // onChangeRadioSelection(selected) {
    //   console.log("selected: ---------> ", selected);
    //   // this.selectedRadio = selected;
    // },
    hideModal(id) {
      console.log("hideModal");
      this.$bvModal.hide(id);
      this.selected = null;
    },
    onSubmit() {
      this.isLoading = true;
      let save_method_payload;
      save_method_payload = structuredClone(this.payload);
      save_method_payload.name =
        this.selected === 0 ? this.form.title : this.form.new_method.title;
      save_method_payload.description =
        this.selected === 0
          ? this.form.description
          : this.form.new_method.description;
      const Repo = RepositoryFactory.get(this.rep);
      (this.selected === 0
        ? Repo.update_method(this.loadedMethodObj.id, save_method_payload)
        : Repo.save_method(save_method_payload)
      )
        .then((response) => {
          const data = response;
          console.log("method", data);
          this.hideModal(this.modalId);
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: metodo salvato`,
          });
          if (this.selected === 1) {
            this.selected = 0;
            this.form.title = this.form.new_method.title;
            this.form.description = this.form.new_method.description;
            this.form.new_method.title = "";
            this.form.new_method.description = "";
          }
          this.$emit("loadedMethodSelected", response.data.data, false);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    addLoadedMethodOption() {
      if (this.hasMethod && !this.loadedMethodOption) {
        this.options.unshift({
          text: "Sovrascrivi metodo caricato",
          value: 0,
        });
        this.form.title = this.loadedMethodObj?.name;
        this.form.description = this.loadedMethodObj?.description;
        this.loadedMethodOption = true;
      }
    },
  },
  components: { BaseIcon, BaseInput, BaseTextarea },
  beforeMount() {
    this.addLoadedMethodOption();
    this.isLoading = false;
    this.selected = this.hasMethod ? null : 1;
  },
  mounted() {
    // usare questo se devo eseguire prima di mostrare
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId !== this.modalId) return;
      this.addLoadedMethodOption();
      this.isLoading = false;
    });
  },
};
</script>
<style scoped>
.modal-desc {
  font-size: 13px;
  padding: 0 5px 0 0;
  line-height: 1.5em;
  margin: 0 0 5px;
}
.loaded-method {
  padding: 10px;
  margin: 0 0 5px;
  background: #fff;
  .value {
    color: #000;
  }
}
</style>
