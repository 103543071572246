<template>
  <b-overlay center :show="isLoading" rounded="sm">
    <div class="data-crossing-wrapper">
      <b-row class="mt-3">
        <b-col align="right">
          <b-btn
            class="btn-create"
            type="button"
            variant="primary"
            size="sm"
            @click="openModal('addResultDataCrossingModal')"
            ><b-icon icon="plus-circle" aria-hidden="true"></b-icon> Aggiungi
            risultati</b-btn
          >
        </b-col>
      </b-row>
      <div class="result-outer-wrapper">
        <!-- <b-btn variant="lisaweb" class="" size="sm" @click="addDataReultIndex()"
        >Aggiungi Dati</b-btn
      > -->
        <div v-if="!activeMethods.length" class="init">
          <div class="content-wrapper">
            <div class="inner-wrapper">
              <p class="disclaimer">
                Clicca sul pulsante "Aggiungi risultati" per iniziare la
                procedura di incrocio dei dati.
              </p>
            </div>
          </div>
        </div>
        <div v-else class="mx-1 mt-3 result-wrapper">
          <!-- <data-result-index
          v-for="(number, index) in els"
          :key="index"
          :index="index"
          :letters="letters"
          @removeSingleDataReultIndex="removeSingleDataReultIndex()"
          @emittedCheckToParent="emittedCheckToParent"
        ></data-result-index> -->
          <crossing-result
            v-for="(result, index) in results"
            :key="index"
            :index="index"
            :result="result"
            :letter="letters[index]"
            @removeResult="removeResult"
          ></crossing-result>
        </div>
      </div>
      <div :data-disabled="!activeMethods.length || activeMethods.length < 2">
        <crossing-method
          @crossingMethodSelected="crossingMethodSelected"
          :onLandedCrossingMethod="onLandData.crossing_method"
        ></crossing-method>
      </div>
      <div class="crossing-actions">
        <!-- <div>
          <p>A: Dati Salvati #1 (12/02/2023)</p>
          <p>B: Dati Salvati #3 (01/10/2022)</p>
        </div> -->
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          :disabled="activeMethods.length < 2 && !crossingMethod"
          @click="execute"
          >Esegui</b-btn
        >
      </div>
      <add-result-data-crossing-modal
        ref="addResultDataCrossing"
        :activeMethods="activeMethods"
        @input="handleAddResultDataCrossingModalInput"
      ></add-result-data-crossing-modal>
    </div>
    <template #overlay>
      <div class="text-center mt-5">
        <base-icon name="loading" width="35" height="35" />
        <p id="cancel-label">Operazione in corso...</p>
      </div>
    </template>
  </b-overlay>
</template>
<script>
// import DataResultIndex from "@/components/analysis/components/dataResultIndex.vue";
import BaseIcon from "@/components/BaseIcon";
import CrossingMethod from "@/components/analysis/components/crossingMethod.vue";
import CrossingResult from "@/components/analysis/components/crossingResult.vue";
import AddResultDataCrossingModal from "@/components/modals/addResultDataCrossingModal";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  mixins: [],
  data() {
    return {
      isLoading: false,
      repository: "analysis",
      resource: "analyses",
      // els: [1, 2],
      letters: ["A"],
      results: [],
      activeMethods: [],
      crossingMethod: null,
      onLandData: this.$route.params.onLandData
        ? this.$route.params.onLandData
        : {},
    };
  },
  components: {
    // DataResultIndex,
    BaseIcon,
    CrossingMethod,
    CrossingResult,
    AddResultDataCrossingModal,
  },
  beforeMount() {},
  mounted() {
    /* check if there are some onLandData */
    if (Object.keys(this.onLandData).length) {
      // let promises = [];
      this.isLoading = true;
      this.activeMethods = this.onLandData.active_methods;
      this.crossingMethod = this.onLandData.crossing_method;
      this.fetchAndProcessAll(this.onLandData.active_methods)
        .then((results) => {
          console.log(results); // Processed data in the order of the original IDs
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        })
        .finally(() => {
          console.log("All fetch operations completed.");
          this.isLoading = false;
          // You can perform other final actions here, like updating UI state
        });
      // console.log("here");
      // if (Object.keys(this.onLandData.loaded_method).length) {
      //   this.loadedMethodObj = this.onLandData.loaded_method;
      //   this.loadedMethod = this.onLandData.loaded_method.id;
      // }
      /* */
      // this.activeResults = [];
      // this.activeRules = [];
      // this.letters = ["A"];
      // this.clickedSource(Object.keys(this.dataSources)[0]);
      // this.activeResults = this.generateActiveResults(
      //   this.onLandData.current_config.fields
      // );
      // this.activeRules = this.generateActiveRules(
      //   this.onLandData.current_config.conditions
      // );
      // for (let i = 0; i < this.activeResults.length; i++) {
      //   this.letters.push(this.getNextStringId(this.letters.at(-1)));
      // }
      /* */
    }
    /* check if there are some onLandData */
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
  methods: {
    async fetchData(id) {
      try {
        // Call Repo.view_data and wait for the response
        const Repo = RepositoryFactory.get(this.rep);
        const response = await Repo.view_data(id);
        const data = response.data.data;
        console.log("response", data);
        // Perform additional actions on response.data.data
        this.handleFetchedData(data);
        return true;
      } catch (error) {
        // Handle error as needed, e.g., return a default value or rethrow
        console.log("There was an error with the view_data", error);
      }
    },
    async fetchAndProcessAll(ids) {
      // Map each ID to its fetch and processing logic
      const results = await Promise.all(
        ids.map(async (id) => {
          return await this.fetchData(id);
        })
      );
      return results; // Results will be in the same order as ids
    },
    execute() {
      this.isLoading = true;
      const data_cross_payload = {
        data: this.activeMethods,
        type: this.crossingMethod,
      };
      console.log("data_cross_payload", data_cross_payload);
      const queryString = "perPage=1";
      const Repo = RepositoryFactory.get(this.rep);
      Repo.data_cross_paginated(data_cross_payload, queryString)
        .then((response) => {
          // Estrapolare le columns per i fields
          if (
            !response?.data?.data?.length ||
            !Object.keys(response?.data?.data?.[0]).length
          ) {
            this.$showSnackbar({
              preset: "error",
              text: `L'incrocio dei dati non ha prodotto risultati.`,
              actionText: "OK",
            });
            return;
          }
          this.$router.push({
            name: `analysis_data_crossing_result.index`,
            params: {
              active_methods: this.activeMethods,
              crossing_method: this.crossingMethod,
              data_table: Object.keys(response.data.data[0]).map((key) => {
                const obj = {};
                obj["key"] = key;
                obj["label"] = key.split(".")[1];
                return obj;
              }),
              // data_values: response.data.data,
              returnUrl: "analysis/data-crossing",
            },
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
      /* */
      /*
      const Repo = RepositoryFactory.get(this.rep);
      Repo.data_cross(data_cross_payload)
        .then((response) => {
          // Estrapolare le columns per i fields
          // Estrapolare i dati per gli items
          console.log("response", response);
          if (
            !response?.data?.data?.length ||
            !Object.keys(response?.data?.data?.[0]).length
          ) {
            this.$showSnackbar({
              preset: "error",
              text: `L'incrocio dei dati non ha prodotto risultati.`,
              actionText: "OK",
            });
            return;
          }
          this.$router.push({
            name: `analysis_data_crossing_result.index`,
            params: {
              active_methods: this.activeMethods,
              crossing_method: this.crossingMethod,
              data_table: Object.keys(response.data.data[0]).map((key) => {
                const obj = {};
                obj["key"] = key;
                obj["label"] = key.split(".")[1];
                return obj;
              }),
              data_values: response.data.data,
              returnUrl: "analysis/data-crossing",
            },
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
      */
    },
    crossingMethodSelected(value) {
      console.log("value", value);
      this.crossingMethod = value;
    },
    removeResult(result) {
      console.log("result", result);
      /* activeMethods */
      const activeMethodsIndex = this.activeMethods.findIndex(
        (method) => method === result.id
      );
      if (activeMethodsIndex === -1) {
        this.$showSnackbar({
          preset: "error",
          text: `Spiacenti, c'è stato un errore con la rimozione del risultato selezionato.`,
        });
        console.log("No active result was found!");
        return;
      }
      /* activeMethods */
      /* activeResults */
      const activeResultsIndex = this.results.findIndex(
        (r) => r.id === result.id
      );
      if (activeResultsIndex === -1) {
        this.$showSnackbar({
          preset: "error",
          text: `Spiacenti, c'è stato un errore con la rimozione del risultato selezionato.`,
        });
        console.log("No active result was found!");
        return;
      }
      /* activeResults */
      this.results.splice(activeResultsIndex, 1);
      this.activeMethods.splice(activeMethodsIndex, 1);
      this.letters.pop();
      this.$showSnackbar({
        preset: "success",
        text: `Azione Completata: risultato rimosso`,
      });
    },
    handleFetchedData(data) {
      let obj = {};
      const { id, updated_at, name, description } = data;
      obj.id = id;
      obj.updated_at = updated_at;
      obj.name = name;
      obj.description = description;
      obj.columns = Object.keys(data?.data?.[0] ?? {}).map((el) => el ?? "");
      this.results.push(obj);
      console.log("results", this.results);
      this.letters.push(this.getNextStringId(this.letters.at(-1)));
    },
    handleAddResultDataCrossingModalInput(method) {
      this.activeMethods.push(method.id);
      console.log("value", method);
      this.isLoading = true;
      this.hideModal("addResultDataCrossingModal");
      const Repo = RepositoryFactory.get(this.rep);
      Repo.view_data(method.id)
        .then((response) => {
          const data = response.data.data;
          console.log("response", response.data.data);
          this.handleFetchedData(data);
          // // const last_letter = this.letters.at(-1);
          // // let obj = { [last_letter]: {} };
          // let obj = {};
          // const data = response.data.data;
          // const { id, updated_at, name, description } = data;
          // // obj[last_letter].updated_at = updated_at;
          // // obj.letter = this.letters.at(-1);
          // obj.id = id;
          // obj.updated_at = updated_at;
          // obj.name = name;
          // obj.description = description;
          // obj.columns = Object.keys(response?.data?.data?.data?.[0] ?? {}).map(
          //   (el) => el ?? ""
          // );
          // this.results.push(obj);
          // console.log("results", this.results);
          // this.letters.push(this.getNextStringId(this.letters.at(-1)));
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: risultato caricato`,
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    openModal(id) {
      console.log("openModal");
      this.$bvModal.show(id);
    },
    hideModal(id) {
      // console.log("hideModal");
      this.$bvModal.hide(id);
    },
    emittedCheckToParent(items) {
      console.log("parent", items);
    },
    // removeSingleDataReultIndex() {
    //   if (this.letters.length === 0 || this.els.legnth === 0) return;
    //   console.log("removeSingleDataReultIndex emitted - v2");
    //   this.letters.pop();
    //   this.els.pop();
    // },
    // addDataReultIndex() {
    //   this.letters.push(this.getNextStringId(this.letters.at(-1)));
    //   const lastEl = this.els.at(-1) + 1;
    //   this.els.push(lastEl);
    // },
    getNextStringId(str) {
      /* https://stackoverflow.com/questions/12504042/what-is-a-method-that-can-be-used-to-increment-letters#answer-68177092 */
      let index = str.length - 1;
      let baseCode = str.charCodeAt(index);
      do {
        baseCode = str.charCodeAt(index);
        let strArr = str.split("");
        if (strArr[index] == "Z") {
          strArr[index] = "A";
          if (index == 0) {
            strArr.unshift("A");
          }
        } else {
          strArr[index] = String.fromCharCode(baseCode + 1);
        }
        str = strArr.join("");
        index--;
      } while (baseCode == 90);
      return str;
    },
  },
};
</script>
<style scoped>
.data-crossing-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 46px 1fr 0.55fr 0.75fr;
  height: calc(100vh - 48px - 52px);
  max-height: calc(100vh - 48px - 52px);
}
.data-crossing-wrapper:has(.disclaimer) {
  grid-template-rows: 46px auto auto auto;
  height: auto;
}
.result-wrapper {
  display: flex;
  gap: 50px;
  width: 100%;
}
.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: -30px;
}
.content-wrapper .disclaimer {
  text-align: center;
  width: 370px;
  font-size: 20px;
  line-height: 1.5em;
  margin: 20px auto;
}
</style>
<style>
.result-wrapper .data-result-index > div > div {
  width: auto !important;
}
.crossing-actions {
  padding: 20px 0 50px;
  border-top: 1px solid #ccc;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
[data-disabled="true"] .inner {
  opacity: 0.5;
  pointer-events: none;
  touch-action: none;
  cursor: default;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
</style>
