import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { getDictionary } from "@/utils/dictionary";
import { joinValues } from "@/utils/transforms";
import FormMixin from "@/mixins/FormMixin";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [FormMixin],
  data() {
    return {
      EXPORT_ERROR: "Errore durante l'esportazione",
      FETCH_ERROR: "Errore durante il caricamento dati",
      TABLE_ERROR: "Non ci sono elementi da esportare",
    };
  },
  methods: {
    joinValues,
    getDictionary,
    createCSVDocument(headers, rows) {
      return headers + rows;
    },
    downloadCSV(content, filename) {
      // Create a blob
      var blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
      var url = URL.createObjectURL(blob);

      // Create a link to download it
      var pom = document.createElement("a");
      pom.href = url;
      pom.setAttribute("download", filename);
      pom.click();
    },
    exportCSV(fields = [], title = "", repository = "", tableRef, filters) {
      let hasItems =
        this.$refs[tableRef]?.items?.length ||
        this.$parent.$refs[this.$parent.tableRef]?.items.length;
      let ref =
        this.$refs[tableRef] || this.$parent.$refs[this.$parent.tableRef];
      let filterOn = ref?.filterOn ? ref.filterOn : {};
      if (hasItems) {
        // Fetch Data from Backend
        this.fetchData(
          repository,
          ref.prepareFilterQueryString(filterOn),
          filters
        )
          .then((data) => {
            // Headers
            const headers = this.getCSVHeaders(fields);
            // Rows
            const rows = this.getCSVRows(fields, data);
            if (headers.length && rows.length) {
              // Document
              const document = this.createCSVDocument(headers, rows);
              // Download PDF
              this.downloadCSV(document, title);
            } else {
              this.onCSVError(this.EXPORT_ERROR);
            }
          })
          .catch(() => {
            this.onCSVError(this.FETCH_ERROR);
            return [];
          });
      } else {
        this.onCSVError(this.TABLE_ERROR);
      }
    },
    async fetchData(repository = null, relations = "", filters = "") {
      if (repository) {
        const Repo = RepositoryFactory.get(repository);
        let queryString = `${relations}&${filters}&perPage=0`;
        return await Repo.index(queryString)
          .then((response) => {
            let data = response.data.data;
            return data;
          })
          .catch(() => {
            this.onCSVError(this.FETCH_ERROR);
            return [];
          });
      } else {
        this.onCSVError(this.FETCH_ERROR);
        return [];
      }
    },
    getCSVHeaders(fields) {
      const headers = [];
      fields.forEach((element) => {
        headers.push(element?.label);
      });
      return headers.join(",") + "\n";
    },
    getCSVRows(fields, data = []) {
      const rows = [];
      data.forEach((item) => {
        const row = {};
        fields.forEach((field) => {
          if (field?.virtual) {
            row[field.label] = field?.virtual(item);
          } else {
            if (item?.[field?.key]) {
              row[field.label] = item?.[field?.key];
            } else {
              row[field.label] = "";
            }
          }
        });
        rows.push(row);
      });
      return rows.map((row) => Object.values(row).join(",")).join("\n");
    },
    onCSVError(error) {
      this.$showSnackbar({
        preset: "error",
        text: `${error}`,
      });
    },
  },
};
