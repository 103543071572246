<template>
  <div class="mt-2">
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono record da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #cell(rowSelector)="row">
            <div class="action-buttons">
              <b-button
                size="sm"
                variant="lisaweb"
                @click="onChosen(row.item)"
                class="mt-1 mr-1"
                title="Seleziona"
                :disabled="parentActiveMethods.includes(row.item.id)"
              >
                Seleziona
              </b-button>
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="customOpenEditModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
          <template #cell(description)="row">
            {{ row?.item?.description ?? "-" }}
          </template>
          <template #cell(download)="row">
            <b-button
              size="sm"
              variant="lisaweb"
              class="mt-1 mr-1"
              title="Download"
              @click="downloadCsv(row.item)"
              :disabled="!hasPermissionDoc"
            >
              <b-icon icon="cloud-download"></b-icon>
            </b-button>
          </template>
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
        </b-table>
      </div>
      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>

        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
      </b-row>

      <b-modal
        :id="infomodalName"
        :dialog-class="infoModal.class"
        :title="infoModal.title"
        :size="infoModal.size"
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
        @show="onModalShow"
        @hidden="onModalHidden"
      >
        <b-tabs content-class="pt-1" lazy v-model="tabIndex">
          <!-- :active="index === tabIndex" -->
          <b-tab
            v-for="(tab, index) in tabs"
            :active="index === tabIndex"
            :key="index"
            :title="tab.text"
            @click="setTab(tab, index)"
            title-item-class="lisaweb"
          >
            <button-group-tab> </button-group-tab>
            <keep-alive>
              <component
                v-if="selected === tab.name"
                :is="selected"
                :resourceId="modalResourceId"
                @fetch="fetch"
              ></component>
            </keep-alive>
          </b-tab>
        </b-tabs>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ButtonGroupTab from "@/components/ButtonGroupTab";
import BaseIcon from "@/components/BaseIcon";
import template from "./template";
import Paginated from "@/components/analysis/result/details/Paginated";
// import Preview from "@/components/analysis/result/details/Preview";
import Edit from "@/components/analysis/result/details/Edit";
// import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  name: "ResultDataAnalysisTableRef",
  extends: template,
  data() {
    return {
      editModal: {
        id: "edit-modal",
        //   class: "edit-info-data-analysis",
        //   content: "",
      },
      infoModal: {
        id: "info-modal",
        title: "Risultati",
        class: "preview-data-analysis",
        content: "",
        size: "xl",
      },
      tabs: [
        // { name: "Preview", text: "Preview" },
        { name: "Paginated", text: "Paginated" },
        { name: "Edit", text: "Edit" },
      ],
      editModalTitle: "Modifica informazioni",
      infoModalTitle: "Risultati",
      editModalSize: "md",
      infoModalSize: "xl",
      selected: "Paginated",
      modalResourceId: null,
      resourceTitle: null,
      resourceDescription: null,
      tabIndex: 0,
    };
  },
  props: {
    parentActiveMethods: {
      type: Array,
      default() {
        return [];
      },
    },
    //   subject: {
    //     type: String,
    //   },
    //   email: {
    //     type: String,
    //   },
  },
  components: {
    ButtonGroupTab,
    BaseIcon,
    // Preview,
    Paginated,
    Edit,
  },
  methods: {
    customOpenEditModal(item, index, target) {
      this.tabIndex = 1; // TabIndex ID dell'Edit
      this.openModal(item, index, target);
    },
    onModalShow() {
      this.infoModal.title =
        this.tabIndex === 1 ? this.editModalTitle : this.infoModalTitle;
      this.infoModal.size =
        this.tabIndex === 1 ? this.editModalSize : this.infoModalSize;
      if (this.tabIndex < this.tabs.length)
        this.setTab(this.tabs[this.tabIndex], this.tabIndex);
    },
    onModalHidden() {
      // this.setTab({ name: "Preview", text: "Preview" }, 0);
      this.setTab({ name: "Paginated", text: "Paginated" }, 0);
    },
    savedData() {
      this.$emit("autofetch");
    },
    openModalEdit(item, index, button) {
      this.modalResourceId = item.id;
      this.resourceTitle = item.name;
      this.resourceDescription = item?.description ?? null;
      this.$bvModal.show(this.editModal.id, button);
    },
    hideModalEdit() {
      console.log("hideModal");
      this.$bvModal.hide(this.editModal.id);
      this.modalResourceId = null;
      this.resourceTitle = null;
      this.resourceDescription = null;
    },
    downloadCsv(item) {
      this.$emit("download", item);
    },
    // fetch(payload) {
    //   console.log("HERE");
    //   this.isLoading = true;
    //   const Repo = RepositoryFactory.get("analysis");
    //   Repo.execute(payload)
    //     .then((response) => {
    //       console.log("response", response);
    //       this.items = response.data.data;
    //     })
    //     .catch((error) => {
    //       let errMsg = this.$getErrorMessage(error);
    //       this.$showSnackbar({
    //         preset: "error",
    //         text: errMsg,
    //       });
    //     })
    //     .finally(() => {
    //       this.isLoading = false;
    //     });
    // },
  },
  computed: {
    hasPermissionDoc() {
      return this.canVerb(this.resource, "download");
    },
  },
  mounted() {
    this.$emit("autofetch");
  },
};
</script>

<style lang="css" scoped></style>
